export function Maintenance(props) {
    return (
        <div className="errorpage_cont">
            <div className="errorpage_text">Under Maintenance</div>
        </div>
    )
}

export function NotAuth(props) {
    return (
        <div className="errorpage_cont">
            <div className="errorpage_text">You are not authorized to see the content</div>
        </div>
    )
}

export function GenericError(props) {
    return (
        <div className="errorpage_cont">
            <div className="errorpage_text">Somethings went wrong, please try again later</div>
        </div>
    )
}

export function CookieDisabled(props) {
    return (
        <div className="errorpage_cont">
            <div className="errorpage_text">Enable third-party cookies to discover the collection</div>
        </div>
    )
}