import './App.css';
import { Displays } from './View/Displays';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Maintenance } from './View/ManageError';


function App() {

  return (
    <div className="App">
      <BrowserRouter>

        <Routes>
          <Route index element={<Displays />} />
          <Route path='/maintenance' element={<Maintenance />} />
          <Route path='/:displayId' element={<Displays />} />
          <Route path='/:displayId/:hotspotId' element={<Displays />} />
          <Route path='/:displayId/:hotspotId/:lookId' element={<Displays />} />
          <Route path='/:displayId/:hotspotId/products/:lookId' element={<Displays />} />
        </Routes>
        
      </BrowserRouter>
    </div>
  );
}

export default App;
