import React, { useState } from 'react';
import EventService from '../Services/Service';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import "react-image-gallery/styles/css/image-gallery.css";
import ScrollContainer from 'react-indiana-drag-scroll';
import toast, { Toaster } from 'react-hot-toast';
import { NotAuth, GenericError, CookieDisabled } from './ManageError';
import { TransformWrapper, TransformComponent, } from "react-zoom-pan-pinch";
let params = new URLSearchParams(window.location.search);
let colors = require('../colors.json')[params.get('tourType')];


export function Displays(props) {

    const eventService = new EventService()

    const [onPreLoading, setOnPreLoading] = useState(true)
    const [onInit, setOnInt] = useState(true)
    const [onLoading, setOnLoading] = useState(true)
    const [loadingDisplays, setLoadingDisplays] = useState(true)
    const [loadingThumbs, setLoadingThumbs] = useState(true)
    const [loadingProducts, setLoadingProducts] = useState(true)
    const [countDisplays, setCountDisplays] = useState(0)
    const [countThumbs, setCountThumbs] = useState(0)
    const [countProducts, setCountProducts] = useState(-1)

    const [redirect, setRedirect] = useState(null);
    const [showMissingArticles, setShowMissingArticles] = useState(null);
    const [cacheSuppurted, setCacheSupported] = useState(true);

    const [displays, setDisplays] = useState([])
    const [showCarousel, setShowCarousel] = useState(true)
    const [currentDisplayId, setCurrentDisplayId] = useState(null)
    const [displayProductsImages, setDisplayProductsImages] = useState(null)
    const [allDisplays, setAllDisplays] = useState([])
    const [cartSmc, setCartSmc] = useState([])
    const [articlesSmc, setArticlesSmc] = useState([])

    const [selectedDisplay, setSelectedDisplay] = useState(0)
    const [selected_image, setSelectedImage] = useState('')
    const [selectedImageId, setSelectedImageID] = useState(0)
    const [selectedHotspot, setSelectedHotspot] = useState(0)
    const [selectedArea, setSelectedArea] = useState('0')
    const [width, setWidth] = useState(3840)
    const [height, setHeight] = useState(2160)
    const [mapWidth, setMapWidth] = useState(1200)
    // const [mapHeight, setMapHeight] = useState(743)
    const [mapHeight, setMapHeight] = useState(790)
    const [heightMapS, setHeightMapS] = useState(86)
    const [widthMapS, setWidthMapS] = useState(135)
    const [heightMapL, setHeightMapL] = useState(378)
    const [widthMapL, setWidthMapL] = useState(600)
    const [areas, setAreas] = useState([])
    const [points, setPoints] = useState([])
    const [no_look_products, setNoLookProducts] = useState([])
    const [showlook, setShowLook] = useState(true)
    const [look_products, setLookProducts] = useState([])
    const [visibleLookIndex, setVisibleLookIndex] = useState(0)
    const [allThumbImages, setAllThumbImages] = useState([])
    const [thumbImages, setThumbImages] = useState([])
    const [openMapStatus, setOpenMapStatus] = useState(false)
    const [filterMenuStatus, setFilterMenuStatus] = useState(false)
    const [hotspotMenuStatus, setHotspotMenuStatus] = useState(false)
    const [refreshHeart, setRefreshHeart] = useState(false)
    const [hideLeftArrow, setHideLeftArrow] = useState(false)
    const [hideRightArrow, setHideRightArrow] = useState(false)
    const [disabledRightArrow, setDisabledRightArrow] = useState(false)
    const [disabledLeftArrow, setDisabledLeftArrow] = useState(false)
    const [disabledCarousel, setDisabledCarousel] = useState(false)
    const [mapLink, setMapLink] = useState(null)
    const [articlesMap, setArticlesMap] = useState(new Map())

    const default_color = colors.secondary
    const default_bg_color = colors.primary
    const secondary_color = colors.secondary
    const tertiary_color = colors.tertiary
    const default_bg_color_disabled = '#8f8f8f'
    const default_bg_color_op = '#0000007d'

    const RateLimitDisplays = 5;
    const RateLimitThumbs = 20;
    const RateLimitProducts = 5;

    const onMessageReceived = (msg) => {
        const { event, payload } = msg.data;
        if (event)
            console.log('ROOT listened >> onMessageReceived', { event, payload });
        switch (event) {
            case 'responseGetArticles':
                //   setProductsData(payload)
                let articles_tmp = []
                let map = new Map()

                payload.map((element) => {
                    articles_tmp.push(element.smc)
                    map.set(element.smc, element.name)
                })
                
                setArticlesMap(map)
                setArticlesSmc(articles_tmp)
                break;
            case 'responseGetCartArticles':
                //    setCartProducts(payload)
                let tmp = []
                payload.map((element) => {
                    tmp.push(element.smc)
                })
                setCartSmc(tmp)

                break;
            case 'responseAddArticleToCart':


                break;
            case 'responseRemoveArticleFromCart':
                if (payload.success)
                    alert('success')
                break;
            default:
                break;
        }
    }
    const getEventID = () => {
        let params = new URLSearchParams(window.location.search);
        let tourType = params.get('tourType');

        if (tourType == 'WHL01' || tourType == 'RET01') {
            return "836aeafd-f705-4a07-a041-2b45b39860ee"
        } else if (tourType == 'WHL03') {
            return "c1aac339-f2eb-4215-bafd-c8610e21b672"
        } else if (tourType == 'EX01') {
            return "d529bba6-d660-432d-a20f-200eb3d4766b"
        } else if (tourType == 'EX02') {
            return "aa84a642-487d-4951-b4b8-636c2ee106f5"
        } else {
            return null
        }
    }

    const sendMessage = (event, payload = null) => {
        console.log(`ROOT emmit >> ${event}`, { event, payload });
        window.parent.postMessage({
            event,
            payload
        }, '*')
    }

    const setDisplay = (display) => {
        //   setDisplayObject(display)
        setDisplaysImages(display.photostudioDisplays)
        //  setDisplays(display.photostudioDisplays)
        sendMessage("getArticles", display.smcList);
    }
    React.useEffect(() => {

    }, [])

    React.useEffect(() => {
        async function preLoad() {
            // let show = await eventService.showMissingArticles();
            // if (show) {
            //     let value = (show == 1 ? true : false)
            //     setShowMissingArticles(value)
            // }

            let cacheSupported = await eventService.isCacheSupported();
            if (!cacheSupported) {
                setRedirect('cookie')
            } else {
                let token = await eventService.getToken();
                if (!token) {
                    setRedirect('notauth')
                } else {
                    let tourType = await eventService.getEventID();
                    if (!tourType) {
                        setRedirect('notauth')
                    } else {
                        eventService.VirtualTour().then((vt) => {
                            setAllThumbImages(Array(vt.photostudioDisplays.length))
                            if (vt.map) setMapLink(vt.map_link)
                            setOnPreLoading(false)
                        }).catch(() => {
                            setRedirect('error')
                        })
                    }
                }
            }
        }
        preLoad()
    }, [])

    //PRELOAD THUMB IMAGES
    React.useEffect(() => {
        if (!onPreLoading) {
            eventService.VirtualTour().then((vt) => {
                if (vt.photostudioDisplays.length < 10) {
                    setShowCarousel(false)
                }

                let displays = vt.photostudioDisplays
                if (countThumbs >= displays.length) {
                    setLoadingThumbs(false)
                } else {
                    const imagesPromises = displays.slice([countThumbs], [countThumbs + RateLimitThumbs]).map(async (d, index) => {
                        var imageData = await eventService.getImageSync('thumb', d.photoStudioThumbImageUrl, cacheSuppurted)
                        allThumbImages[index + countThumbs] = {
                            thumbnail: imageData ? imageData : '',
                            id: d.id
                        }
                    })
                    Promise.all(imagesPromises).then(() => {
                        setCountThumbs(countThumbs + RateLimitThumbs)
                    }).catch(() => {
                        console.log('error')
                    })
                }
            })
        }
    }, [countThumbs, onPreLoading])

    //PRELOAD DISPLAY IMAGES
    React.useEffect(() => {
        if (!onPreLoading) {
            if (cacheSuppurted) {
                if (countDisplays >= RateLimitDisplays) setLoadingDisplays(false)

                if (countDisplays < RateLimitDisplays || (!loadingThumbs && !loadingProducts)) {
                    eventService.VirtualTour().then((vt) => {
                        let displays = vt.photostudioDisplays

                        if (countDisplays <= displays.length) {
                            const imagesPromises = displays.slice([countDisplays], [countDisplays + RateLimitDisplays]).map(async (d) => {
                                await eventService.getImageSync('display', d.photoStudioImageUrl, cacheSuppurted)
                            })
                            Promise.all(imagesPromises).then(() => {
                                setCountDisplays(countDisplays + RateLimitDisplays)
                            }).catch(() => {
                                console.log('error')
                            })
                        }
                    })
                }
            } else {
                setLoadingDisplays(false)
            }
        }
    }, [countDisplays, loadingThumbs, loadingProducts, onPreLoading])

    //PRELOAD PRODUCT IMAGES
    React.useEffect(() => {
        if (!onPreLoading && cacheSuppurted) {
            if (currentDisplayId != null) {
                eventService.VirtualTour().then((vt) => {
                    eventService.extractProductsImagesLink(vt.photostudioDisplays, currentDisplayId).then((productsImages) => {
                        setDisplayProductsImages(productsImages)
                    })
                })
            }
        }
    }, [currentDisplayId, onPreLoading])

    React.useEffect(() => {
        if (currentDisplayId != null) {
            setLoadingProducts(true)
            setCountProducts(0)
        }
    }, [displayProductsImages])

    React.useEffect(() => {
        if (currentDisplayId != null) {
            if (displayProductsImages && displayProductsImages.length > 0) {
                var currDisplayId = currentDisplayId
                if (countProducts >= displayProductsImages.length) setLoadingProducts(false)

                if (countProducts <= displayProductsImages.length) {
                    const imagesPromises = displayProductsImages.slice([countProducts], [countProducts + RateLimitProducts]).map(async (d) => {
                        await eventService.getImageSync('product', d, cacheSuppurted, cacheSuppurted)
                    })
                    Promise.all(imagesPromises).then(() => {
                        if (currDisplayId == currentDisplayId) {
                            setCountProducts(countProducts + RateLimitProducts)
                        }
                    }).catch(() => {
                        console.log('error')
                    })
                }
            }
        }
    }, [countProducts])

    // //TO CHECK THE PROLOAD QUEUE
    // React.useEffect(() => {
    //     console.log('--> TH: ' + countThumbs + ' - DS: ' + countDisplays + ' - PD: ' + countProducts + ' - CD: ' + currentDisplayId + ' (TH: ' + loadingThumbs + ' - DS: ' + loadingDisplays + ' - PD: ' + loadingProducts +')')
    // }, [countThumbs, countDisplays, countProducts, loadingThumbs, loadingDisplays, loadingProducts])

    //LOAD WEBSITE
    React.useEffect(() => {
        if (!onPreLoading) {
            eventService.VirtualTour().then((vt) => {
                if (!loadingThumbs && !loadingDisplays) {
                    if (onLoading) {
                        setOnLoading(false)
                        setDisplay(vt)
                        sendMessage('getCartArticles', null)
                    }
                } else {
                    if (onInit) {
                        setOnInt(false)

                        window.addEventListener('message', event => onMessageReceived(event));

                        setAllDisplays(vt)
                        setAreas(vt.areas)

                        //initial draw displays on map
                        setTimeout(() => {
                            drawDisplays(vt.photostudioDisplays, 0)
                        }, 300);

                        //detect click out of filter menu and close it
                        window.addEventListener('click', function (e) {
                            if (document.getElementById('filter_menu') && !document.getElementById('filter_menu').contains(e.target) && document.getElementById('filter_menu') && !document.getElementById('filter_button').contains(e.target)) {
                                setFilterMenuStatus(false)
                            }
                        });
                    }
                }
            })
        }
    }, [loadingDisplays, loadingThumbs, onPreLoading])

    async function select(index) {
        setSelectedDisplay(index)
    }

    //check if it needs to draw arrows
    function checkArrow(look_products, no_look_products) {
        let a = document.getElementsByClassName('products_container')

        //check left arrow
        if (a[0] && parseInt(look_products.length) + parseInt(no_look_products.length) == 1 && a[0].scrollLeft == 0)
            setHideLeftArrow(true)
        else
            setHideLeftArrow(false)

        //check right arrow
        if (a[0] && parseInt(look_products.length) + parseInt(no_look_products.length) == 1 &&
            ((a[0].scrollWidth < a[0].scrollLeft + window.innerWidth - 30 && !showlook) || (a[0].scrollWidth < a[0].scrollLeft + window.innerWidth - 420 && showlook)))
            setHideRightArrow(true)
        else
            setHideRightArrow(false)
    }

    //handles the thumbnail carousel (if the selected thumbnail go out the window)
    function scrollThumbCarousel() {
        if (mapLink) {
            let thumb_carousel = document.getElementsByClassName('thumbnails_container')
            let selected_thumb = document.getElementsByClassName('selected-image')

            let cont = 0
            let currentScrollLeft = thumb_carousel[0].scrollLeft
            let limit = 410
            if (!openMapStatus)
                limit = 150

            //there is a map

            try {
                if (selected_thumb[0].x + 180 > window.innerWidth)
                    while (selected_thumb[0].x + 180 > window.innerWidth && cont < 1000 && thumb_carousel[0].scrollWidth - window.innerWidth > currentScrollLeft) {
                        cont++
                        thumb_carousel[0].scrollLeft = thumb_carousel[0].scrollLeft + 180
                        currentScrollLeft = currentScrollLeft + 180
                    }
                else if (selected_thumb[0].x < limit)
                    while (selected_thumb[0].x < limit && cont < 1000) {
                        thumb_carousel[0].scrollLeft = thumb_carousel[0].scrollLeft - 150
                        cont++
                    }
            } catch { }
        }

        //there isn't a map
        else {
            // console.log('ihere')
            let thumb_carousel = document.getElementsByClassName('thumbnails_container_no_map')
            let selected_thumb = document.getElementsByClassName('selected-image')

            let cont = 0
            let currentScrollLeft = thumb_carousel[0].scrollLeft

            let limit = 0
            try {
                if (selected_thumb[0].x + 180 > window.innerWidth)
                    while (selected_thumb[0].x + 180 > window.innerWidth && cont < 1000 && thumb_carousel[0].scrollWidth - window.innerWidth > currentScrollLeft) {
                        cont++
                        thumb_carousel[0].scrollLeft = thumb_carousel[0].scrollLeft + 180
                        currentScrollLeft = currentScrollLeft + 180
                    }
                else if (selected_thumb[0].x < limit)
                    while (selected_thumb[0].x < limit && cont < 1000) {
                        thumb_carousel[0].scrollLeft = thumb_carousel[0].scrollLeft - 150
                        cont++
                    }
            } catch { }
        }
    }

    function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
        var doRatio = width / height;
        var cRatio = containerWidth / containerHeight;
        var targetWidth = 0;
        var targetHeight = 0;
        var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

        if (test) {
            targetWidth = containerWidth;
            targetHeight = targetWidth / doRatio;
        } else {
            targetHeight = containerHeight;
            targetWidth = targetHeight * doRatio;
        }

        return {
            width: targetWidth,
            height: targetHeight,
            x: (containerWidth - targetWidth) / 2,
            y: (containerHeight - targetHeight) / 2
        };
    }

    //handles the click on thumb or on map point
    function viewPoint(displays, e, index, status_map, hotspot_click) {

        if (index == 0) {
            e.preventDefault()
        }
        if (!disabledCarousel) {
            setDisabledCarousel(true)
            setDisabledLeftArrow(true)
            setDisabledRightArrow(true)
            if (displays && displays.length > 0) {
                window.history.replaceState({ page: "display_page" }, "display_page", displays[index].id + window.location.search);

                let tmpPoints = []

                //QUESTO NON DOVREBBE SERVIRE ALTRIMENTI RICARICA TUTTO
                ////setLoadingDisplays(true)

                setCurrentDisplayId(displays[index].id)


                let values = getObjectFitSize(false, window.innerWidth, window.innerHeight, width, height);
                displays[index].displays.map((h) => {

                    let pX = ((parseInt(h.xPos) * values.width) / width)
                    let pY = (parseInt(h.yPos) * values.height) / height
                    let point = {
                        x: pX + values.x - 10,
                        y: pY + values.y - 10
                    }
                    tmpPoints.push(point)
                })

                select(index).then(() => {
                    setTimeout(() => {

                        setPoints(tmpPoints)
                    }, 100);
                    setSelectedImageID(index)

                    eventService.getImage('display', displays[index].photoStudioImageUrl, cacheSuppurted).then((s) => {
                        setSelectedImage(<img className='sel_image' src={s} alt='loading' onClick={() => setOpenMapStatus(false)} />)
                    }).catch(() => {
                        setSelectedImage(<img className='sel_image' src='' alt='loading' onClick={() => setOpenMapStatus(false)} />)
                    })
                    if (status_map) {
                        if (!openMapStatus && !hotspot_click) {
                            drawDisplays(displays, index)
                        }
                        else {
                            drawDisplaysL(displays, index)
                        }
                    }
                })
            }
            setTimeout(() => {
                setDisabledCarousel(false)
                setDisabledLeftArrow(false)
                setDisabledRightArrow(false)
                checkArrow()

            }, 700);
            setTimeout(() => {

                scrollThumbCarousel()
            }, 300);
        }
        if (!hotspot_click) {
            setOpenMapStatus(false)
            drawDisplays(displays, index)
        }
        //controllare se serve
    }

    //set displays to draw (filtered or not)
    function setDisplaysImages(displays_tmp) {

        setDisplays(displays_tmp)

        let thumbImages = Array(displays_tmp.length)
        setThumbImages(thumbImages)

        let path = window.location.pathname
        let displayId = path.split('/')[1]
        let hotspotId = path.split('/')[2]
        let lookId = path.split('/')[3]
        let productsId = path.split('/')[4]
        let look_p = []
        let no_look_p = []

        let found = false
        displays_tmp.map((d, index) => {
            allThumbImages.map((i) => {
                if (d.id == i.id) {
                    thumbImages[index] = i
                }
            })

            //displayId è valido, ho inserito un url specifico
            if (displayId && displayId === d.id) {

                setCurrentDisplayId(displayId)

                found = true
                let tmpPoints = []
                let values = getObjectFitSize(false, window.innerWidth, window.innerHeight, 3840, 2160);
                displays_tmp[index].displays.map((h) => {

                    let pX = ((parseInt(h.xPos) * values.width) / width)
                    let pY = (parseInt(h.yPos) * values.height) / height
                    let point = {
                        x: pX + values.x - 10,
                        y: pY + values.y - 10
                    }
                    tmpPoints.push(point)
                })


                setPoints(tmpPoints)
                setSelectedDisplay(index)

                eventService.getImage('display', d.photoStudioImageUrl, cacheSuppurted).then((s) => {
                    setSelectedImage(<img className='sel_image' src={s} alt='loading' onClick={() => setOpenMapStatus(false)} />)
                }).catch(() => {
                    setSelectedImage(<img className='sel_image' src='' alt='loading' onClick={() => setOpenMapStatus(false)} />)
                })

                setSelectedImageID(index)

                if (hotspotId && d.displays && d.displays.length > 0) {
                    d.displays.map((h, ind) => {
                        if (h.id === hotspotId) {
                            setSelectedHotspot(ind)
                            setHotspotMenuStatus(true)
                            setLookProducts(h.look_hotspots)
                            setNoLookProducts(h.no_look_hotspots)

                            h.look_hotspots.map((look) => {
                                eventService.setImage('product', look.lookImageLink, look.id, cacheSuppurted)

                                if (look.products.length > 0) {
                                    look.products.map((p, prodIndex) => {
                                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                                    })
                                }
                            })

                            h.no_look_hotspots.map((products_container) => {
                                if (products_container.products.length > 0) {
                                    products_container.products.map((p, prodIndex) => {
                                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                                    })
                                }
                            })
                            look_p = h.look_hotspots
                            no_look_p = h.no_look_hotspots

                        }
                    })

                    if (lookId === 'products') {
                        setShowLook(false)
                        setVisibleLookIndex(parseInt(productsId))
                    }
                    else if (lookId) {
                        setShowLook(true)
                        setVisibleLookIndex(parseInt(lookId))
                    }
                    setTimeout(() => {
                        checkArrow(look_p, no_look_p)
                    }, 300);
                }
            }

        })

        //non ho inserito un url specifico o non l'ho trovato
        if ((displayId && !found) || !displayId && displays_tmp.length > 0) {

            setCurrentDisplayId(displays_tmp[0].id)
            eventService.getImage('display', displays_tmp[0].photoStudioImageUrl, cacheSuppurted).then((s) => {
                setSelectedImage(<img className='sel_image' src={s} alt='loading' onClick={() => setOpenMapStatus(false)} />)
            }).catch(() => {
                setSelectedImage(<img className='sel_image' src='' alt='loading' onClick={() => setOpenMapStatus(false)} />)
            })

            setSelectedImageID(0)

            let tmpPoints = []
            let values = getObjectFitSize(false, window.innerWidth, window.innerHeight, 3840, 2160);
            displays_tmp[0].displays.map((h) => {

                let pX = ((parseInt(h.xPos) * values.width) / width)
                let pY = (parseInt(h.yPos) * values.height) / height
                let point = {
                    x: pX + values.x - 10,
                    y: pY + values.y - 10
                }
                tmpPoints.push(point)
            })


            setPoints(tmpPoints)
            if (!openMapStatus) {
                drawDisplays(displays_tmp, 0)
            }
            else {
                drawDisplaysL(displays_tmp, 0)
            }

            window.history.pushState({ page: "display_page" }, "display_page", displays_tmp[0].id + window.location.search);
        }

        setTimeout(() => {
            scrollThumbCarousel()
        }, 200);
    }

    //draw displays on closed map
    function drawDisplays(displays_tmp, selected_display) {
        // if (!mapLink) return;

        // let cont = document.getElementById('displays_content')
        // if (cont) {
        //     cont.innerHTML = ''
        //     displays_tmp.forEach(function callback(display, index) {
        //         let sphere = document.createElement('div')
        //         let top = (display.yPos * heightMapS) / mapHeight;
        //         let left = (display.xPos * widthMapS) / mapWidth;

        //         let style = " top: " + top + "px; left: " + left + 'px;' + '    background-color: #80808078;  border: 0.5px solid #80808078; width:3px; height:3px; '
        //         if (index == selected_display)
        //             style = " top: " + top + "px; left: " + left + 'px;' + '    background-color:' + default_color + ';  border: 0.5px solid #80808078; width:5px; height:5px; '
        //         ///   let style = " top: " + top + "%; left: " + left + '%;' + 'background-image:url("https://showroom.globalbit.gucci/PHOTOS/showroom/' + display.imageLink + '.jpeg"); width:3px; height:3px; '

        //         sphere.setAttribute('style', style)
        //         sphere.setAttribute('class', 'display pointer')

        //         /*     sphere.addEventListener('click', function (e) {
        //                  e.preventDefault()
        //                  setSelectedDisplay(index)
        //                  viewPoint(displays_tmp, e, index)
        //              })*/
        //         cont.appendChild(sphere)
        //     });
        // }
    }

    //draw displays on opened map
    function drawDisplaysL(displays_tmp, selected_display) {
        // if (!mapLink) return;

        // let cont = document.getElementById('displays_content')
        // if (cont) {
        //     cont.innerHTML = ''
        //     displays_tmp.forEach(function callback(display, index) {


        //         let sphere = document.createElement('div')
        //         let top = (display.yPos * heightMapL) / mapHeight;
        //         let left = (display.xPos * widthMapL) / mapWidth;


        //         //let style = " top: " + top + "%; left: " + left + '%;' + 'background-image:url("https://showroom.globalbit.gucci/PHOTOS/showroom/' + display.imageLink + '.jpeg"); width:7px; height:7px; margin-top:-3px; margin-left:-3px'
        //         let style = " top: " + top + "px; left: " + left + 'px;color:white; font-size:7px;line-height:8px;' + ' background-color: #80808078;  border: 0.5px solid #80808078;width:7px; height:7px; margin-top:-3px; margin-left:-3px'
        //         if (index == selected_display)
        //             style = " top: " + top + "px; left: " + left + 'px;color:white; font-size:7px;line-height:8px;' + ' background-color: ' + default_color + ';  border: 0.5px solid #80808078;width:10px; height:10px; margin-top:-3px; margin-left:-3px'

        //         sphere.setAttribute('style', style)
        //         sphere.setAttribute('class', 'display pointer')

        //         sphere.addEventListener('click', function (e) {
        //             e.preventDefault()
        //             setSelectedDisplay(index)
        //             viewPoint(displays_tmp, e, index, true, true)
        //         })

        //         cont.appendChild(sphere)
        //     });
        // }
    }

    //open the map
    const openMap = () => {
        let gallery = document.getElementsByClassName('thumbnails_container')
        if (openMapStatus == false) {
            setOpenMapStatus(true)
            gallery[0].classList.add(0, 'thumbnails_container_open')

            drawDisplaysL(displays, selectedDisplay)
        }
        else {
            setOpenMapStatus(false)
            gallery[0].classList.remove('thumbnails_container_open')
            drawDisplays(displays, selectedDisplay)
        }
    }

    const checkClickClose = () => {

        // let outside_click_class = ['product_img', 'heart', 'selected_display_point', 'look_img', 'see_details_button',
        //     'product_smc', 'product_card', 'empty product_card', 'product_name', 'previous_arrow', 'left_arrow', 'next_arrow', 'products_container look_products_container indiana-scroll-container indiana-scroll-container--hide-scrollbars',
        //     'products_container indiana-scroll-container indiana-scroll-container--hide-scrollbars']

        // document.body.addEventListener('click', function (event) {
        //     if (!outside_click_class.includes(event.srcElement.className)) {
        //         exitHotspot()
        //     }
        // });
    }

    //open display menu
    const openHotspotMenu = (index) => {
        let tot_count = 0
        setHideLeftArrow(true)
        setHideRightArrow(true)
        setSelectedHotspot(index)

        setTimeout(() => {
            //load all products and look images
            if (displays.length > 0 && displays[selectedDisplay] && displays[selectedDisplay].displays && displays[selectedDisplay].displays.length > 0) {
                displays[selectedDisplay].displays.map((d, index2) => {

                    if (displays[selectedDisplay].displays[index2].look_hotspots.length > 0) {

                        eventService.setImage('product', displays[selectedDisplay].displays[index2].look_hotspots[0].lookImageLink, displays[selectedDisplay].displays[index2].look_hotspots[0].id, cacheSuppurted)

                        if (displays[selectedDisplay].displays[index2].look_hotspots[0].products.length > 0) {
                            displays[selectedDisplay].displays[index2].look_hotspots[0].products.map((p, prodIndex) => {
                                eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                            })
                        }
                    }
                    else if (displays[selectedDisplay].displays[index2].no_look_hotspots.length > 0) {
                        if (displays[selectedDisplay].displays[index2].no_look_hotspots[0].products.length > 0) {
                            displays[selectedDisplay].displays[index2].no_look_hotspots[0].products.map((p, prodIndex) => {
                                eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                            })
                        }
                    }

                    if (index == index2) {

                        setLookProducts(displays[selectedDisplay].displays[index2].look_hotspots)
                        setNoLookProducts(displays[selectedDisplay].displays[index2].no_look_hotspots)
                        tot_count = displays[selectedDisplay].displays[index2].look_hotspots.length + displays[selectedDisplay].displays[index2].no_look_hotspots.length
                        if (displays[selectedDisplay].displays[index2].look_hotspots.length + displays[selectedDisplay].displays[index2].no_look_hotspots.length > 1) {
                            setHideRightArrow(false)
                            setHideLeftArrow(false)

                        }
                        if (displays[selectedDisplay].displays[index2].look_hotspots.length > 0) {
                            setShowLook(true)
                            window.history.pushState({ page: "another" }, "another", displays[selectedDisplay].id + '/' + displays[selectedDisplay].displays[index2].id + '/' + visibleLookIndex + window.location.search);
                        } else {
                            if (displays[selectedDisplay].displays[index2].no_look_hotspots[0].products.length > 0) {
                                setShowLook(false)
                            }
                            window.history.pushState({ page: "another" }, "another", displays[selectedDisplay].id + '/' + displays[selectedDisplay].displays[index2].id + '/products/' + 0 + window.location.search);
                        }
                    }
                })
            }
            setHotspotMenuStatus(true)
            setTimeout(() => {
                let cards = document.getElementsByClassName('product_card')
                let prod_cont = document.getElementsByClassName('products_container')
                let last_card = cards[cards.length - 1]
                if (last_card.getBoundingClientRect().x + 280 >= window.innerWidth) {

                    setHideRightArrow(false)
                    setHideLeftArrow(false)
                }
                if (prod_cont[0].scrollLeft == 0 && tot_count == 1)
                    setHideLeftArrow(true)

                checkClickClose()
            }, 50);
        }, 300);

        setOpenMapStatus(false)
    }

    //apply filter
    const filterByArea = () => {
        setCurrentDisplayId(displays[0].id)
        setSelectedImageID(0)
        if (selectedArea === '0') {
            window.history.pushState({ page: "display_page" }, "display_page", '/' + window.location.search);
            setDisplaysImages(allDisplays.photostudioDisplays)

        } else {
            let filtered = []
            let found = false
            allDisplays.photostudioDisplays.map((d) => {
                found = false
                d.displays.map((display) => {
                    if (display.areaID === selectedArea && !found) {
                        //controllare che non esiste già in filtered
                        found = true
                        filtered.push(d)
                    }
                })
            })
            if (filtered.length > 0) {
                setDisplaysImages(filtered)
            }
            else {
                toast('No displays for the selected filter!');
                if (allDisplays.photostudioDisplays && allDisplays.photostudioDisplays.length > 0) {
                    setDisplaysImages(allDisplays.photostudioDisplays)
                }
            }
        }
    }

    //reset filter select
    const reset = () => {
        setSelectedImageID(0)
        setSelectedArea('0')
        if (allDisplays.photostudioDisplays && allDisplays.photostudioDisplays.length > 0) {
            window.history.pushState({ page: "display_page" }, "display_page", '/' + window.location.search);
            setCurrentDisplayId(allDisplays.photostudioDisplays[0].id)
            setDisplaysImages(allDisplays.photostudioDisplays)
        }
    }

    //handle filter
    const handleChangeArea = (e) => {
        setSelectedArea(e.target.value)
    }

    //go to previous photostudiodisplay
    const goPrevious = () => {
        //controllare se serve
        setOpenMapStatus(false)
        if (!disabledLeftArrow && !disabledRightArrow && !disabledCarousel) {
            let newIndex = selectedImageId
            if (selectedImageId > 0) {
                newIndex = selectedImageId - 1
                setSelectedImageID(newIndex)
                setCurrentDisplayId(displays[newIndex].id)
                eventService.getImage('display', displays[newIndex].photoStudioImageUrl, cacheSuppurted).then((s) => {
                    setSelectedImage(<img className='sel_image' src={s} alt='loading' onClick={() => setOpenMapStatus(false)} />)
                }).catch(() => {
                    setSelectedImage(<img className='sel_image' src='' alt='loading' onClick={() => setOpenMapStatus(false)} />)
                })
            }
            if (displays && displays.length > 0) {

                window.history.replaceState({ page: "display_page" }, "display_page", displays[newIndex].id + window.location.search);

                let tmpPoints = []

                let values = getObjectFitSize(false, window.innerWidth, window.innerHeight, 3840, 2160);
                displays[newIndex].displays.map((h) => {

                    let pX = ((parseInt(h.xPos) * values.width) / width)
                    let pY = (parseInt(h.yPos) * values.height) / height
                    let point = {
                        x: pX + values.x - 10,
                        y: pY + values.y - 10
                    }
                    tmpPoints.push(point)
                })


                select(newIndex).then(() => {
                    setTimeout(() => {
                        setPoints(tmpPoints)
                    }, 100);

                    // if (!openMapStatus) {
                    drawDisplays(displays, newIndex)
                    //}
                    //else {
                    // drawDisplaysL(displays, newIndex)
                    //}
                })
            }
            setDisabledLeftArrow(true)
            setDisabledRightArrow(true)
            setDisabledCarousel(true)

            setTimeout(() => {
                scrollThumbCarousel()
            }, 100);

            setTimeout(() => {
                setDisabledLeftArrow(false)
                setDisabledRightArrow(false)
                setDisabledCarousel(false)
            }, 700);
        }
    }

    //go to next photostudiodisplay
    const goNext = () => {
        //controllare se serve
        setOpenMapStatus(false)
        if (!disabledRightArrow && !disabledLeftArrow && !disabledCarousel) {
            let newIndex = selectedImageId

            if (selectedImageId < displays.length - 1) {
                newIndex = selectedImageId + 1
                setSelectedImageID(newIndex)
                setCurrentDisplayId(displays[newIndex].id)
                eventService.getImage('display', displays[newIndex].photoStudioImageUrl, cacheSuppurted).then((s) => {
                    setSelectedImage(<img className='sel_image' src={s} alt='loading' onClick={() => setOpenMapStatus(false)} />)
                }).catch(() => {
                    setSelectedImage(<img className='sel_image' src='' alt='loading' onClick={() => setOpenMapStatus(false)} />)
                })
            }
            if (displays && displays.length > 0) {

                window.history.replaceState({ page: "display_page" }, "display_page", displays[newIndex].id + window.location.search);

                let tmpPoints = []

                let values = getObjectFitSize(false, window.innerWidth, window.innerHeight, 3840, 2160);
                displays[newIndex].displays.map((h) => {

                    let pX = ((parseInt(h.xPos) * values.width) / width)
                    let pY = (parseInt(h.yPos) * values.height) / height
                    let point = {
                        x: pX + values.x - 10,
                        y: pY + values.y - 10
                    }
                    tmpPoints.push(point)
                })

                select(newIndex).then(() => {

                    setTimeout(() => {

                        setPoints(tmpPoints)
                    }, 100);

                    //   if (!openMapStatus) {
                    drawDisplays(displays, newIndex)
                    /*    }
                        else {
                            drawDisplaysL(displays, newIndex)
                        }
                        */
                })

            }
            setDisabledRightArrow(true)
            setDisabledLeftArrow(true)
            setDisabledCarousel(true)
            setTimeout(() => {
                scrollThumbCarousel()
            }, 100);

            setTimeout(() => {
                setDisabledRightArrow(false)
                setDisabledLeftArrow(false)
                setDisabledCarousel(false)
            }, 700);
        }

    }

    //see detail button manager
    const openDetailView = (p) => {
        // setHotspotMenuStatus(false)
        let article = {
            articleId: p.smc,
            currentSecond: 10
        }
        let link = {
            url: window.location.protocol + window.location.host + window.location.pathname + window.window.location.search,
            target: p.smc,
            currentSeconds: 10
        }
        sendMessage('navigateToLink', link)
        sendMessage('navigateToArticle', article)
    }

    //scroll right in the display menu
    const scrollRight = () => {
        let a = document.getElementsByClassName('products_container')

        if (a[0].scrollWidth > a[0].scrollLeft + window.innerWidth - 390 && showlook || a[0].scrollWidth > a[0].scrollLeft + window.innerWidth && !showlook) {
            a[0].scrollLeft += 855

            if (parseInt(look_products.length) + parseInt(no_look_products.length) == 1) {
                setHideRightArrow(true)
                setTimeout(() => {
                    let cards = document.getElementsByClassName('product_card')
                    let last_card = cards[cards.length - 1]
                    if (last_card.getBoundingClientRect().x + 300 >= window.innerWidth) {
                        setHideRightArrow(false)
                    }
                }, 50);
            }
            else {
                setHideRightArrow(false)
            }

            setHideLeftArrow(false)
        }
        else {
            if (parseInt(look_products.length) + parseInt(no_look_products.length) == 1) {
                setHideRightArrow(true)
                setHideLeftArrow(false)
            }

            if (parseInt(visibleLookIndex + 1) >= look_products.length && showlook == true || parseInt(visibleLookIndex + 1) >= no_look_products.length && !showlook) {
                if (showlook && no_look_products.length > 0) {
                    setShowLook(false)
                    setVisibleLookIndex(0)
                    no_look_products[0].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                    window.history.pushState({ page: "product_page" }, "product_page", 'products/' + window.location.search);
                    window.history.replaceState({ page: "product_page" }, "product_page", 0 + window.location.search);
                }
                else if (!showlook && look_products.length > 0) {
                    let path = window.location.pathname
                    let displayId = path.split('/')[1]
                    let hotspotId = path.split('/')[2]
                    window.history.pushState({ page: "display_page" }, "display_page", '/' + window.location.search);

                    setTimeout(() => {
                        setShowLook(true)
                        window.history.pushState({ page: "display_page" }, "display_page", displayId + '/' + hotspotId + '/' + window.location.search);
                        window.history.replaceState({ page: "look_page" }, "look_page", 0 + window.location.search);
                        setVisibleLookIndex(0)

                        eventService.setImage('product', look_products[0].lookImageLink, look_products[0].id, cacheSuppurted)
                        look_products[0].products.map((p, ind) => {
                            eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                        })

                    }, 200)

                }
                else if (showlook && look_products.length > 0) {
                    window.history.replaceState({ page: "look_page" }, "look_page", 0 + window.location.search);
                    setVisibleLookIndex(0)
                    eventService.setImage('product', look_products[0].lookImageLink, look_products[0].id, cacheSuppurted)
                    look_products[0].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                }
                else if (!showlook && no_look_products.length > 0) {
                    setVisibleLookIndex(0)
                    no_look_products[0].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                    window.history.replaceState({ page: "product_page" }, "product_page", 0 + window.location.search);
                }

            }
            else {
                if (showlook) {
                    window.history.replaceState({ page: "look_page" }, "look_page", parseInt(visibleLookIndex) + 1 + window.location.search);
                    eventService.setImage('product', look_products[parseInt(visibleLookIndex + 1)].lookImageLink, look_products[parseInt(visibleLookIndex + 1)].id, cacheSuppurted)
                    look_products[parseInt(visibleLookIndex + 1)].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                }
                else {

                    window.history.replaceState({ page: "product_page" }, "product_page", parseInt(visibleLookIndex) + 1 + window.location.search);
                    eventService.setImage('product', no_look_products[parseInt(visibleLookIndex + 1)].lookImageLink, no_look_products[parseInt(visibleLookIndex + 1)].id, cacheSuppurted)
                    no_look_products[parseInt(visibleLookIndex + 1)].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                }

                setVisibleLookIndex(Math.abs(parseInt(visibleLookIndex) + 1))
            }

        }

    }
    //scroll left in the display menu
    const scrollLeft = () => {

        let a = document.getElementsByClassName('products_container')
        if (0 <= a[0].scrollLeft - 855) {
            a[0].scrollLeft -= 855
            setHideLeftArrow(false)
            setHideRightArrow(false)
        }
        else {
            if (parseInt(look_products.length) + parseInt(no_look_products.length) == 1) {
                setHideLeftArrow(true)
                setHideRightArrow(false)
            }

            if (visibleLookIndex - 1 < 0) {
                if (showlook && no_look_products.length > 0) {
                    setShowLook(false)
                    setVisibleLookIndex(no_look_products.length - 1)
                    no_look_products[no_look_products.length - 1].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                    window.history.pushState({ page: "product_page" }, "product_page", 'products/' + window.location.search);
                    window.history.replaceState({ page: "product_page" }, "product_page", no_look_products.length - 1 + window.location.search);
                }
                else if (!showlook && look_products.length > 0) {
                    let path = window.location.pathname
                    let displayId = path.split('/')[1]
                    let hotspotId = path.split('/')[2]
                    window.history.pushState({ page: "display_page" }, "display_page", '/' + window.location.search);
                    setTimeout(() => {

                        setShowLook(true)
                        window.history.pushState({ page: "display_page" }, "display_page", displayId + '/' + hotspotId + '/' + window.location.search);
                        window.history.replaceState({ page: "look_page" }, "look_page", look_products.length - 1 + window.location.search);

                        setVisibleLookIndex(look_products.length - 1)
                        eventService.setImage('product', look_products[look_products.length - 1].lookImageLink, look_products[look_products.length - 1].id, cacheSuppurted)
                        look_products[look_products.length - 1].products.map((p, ind) => {
                            eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                        })
                    }, 50);
                }
                else if (showlook && look_products.length > 0) {
                    setVisibleLookIndex(look_products.length - 1)
                    eventService.setImage('product', look_products[look_products.length - 1].lookImageLink, look_products[look_products.length - 1].id, cacheSuppurted)
                    look_products[look_products.length - 1].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                    window.history.replaceState({ page: "look_page" }, "look_page", (look_products.length - 1) + window.location.search);
                }
                else if (!showlook && no_look_products.length > 0) {
                    setVisibleLookIndex(no_look_products.length - 1)
                    no_look_products[no_look_products.length - 1].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                    window.history.replaceState({ page: "product_page" }, "product_page", no_look_products.length - 1 + window.location.search);
                }

            }
            else {
                if (showlook) {
                    window.history.replaceState({ page: "look_page" }, "look_page", visibleLookIndex - 1 + window.location.search);
                    eventService.setImage('product', look_products[visibleLookIndex - 1].lookImageLink, look_products[visibleLookIndex - 1].id, cacheSuppurted)
                    look_products[parseInt(visibleLookIndex - 1)].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                }
                else {
                    window.history.replaceState({ page: "product_page" }, "product_page", visibleLookIndex - 1 + window.location.search);
                    no_look_products[parseInt(visibleLookIndex - 1)].products.map((p, ind) => {
                        eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
                    })
                }

                setVisibleLookIndex(parseInt(visibleLookIndex) - 1)
            }
            a[0].scrollLeft = 0
        }
    }

    //close hotspot
    const exitHotspot = () => {
        setShowLook(true)
        setHotspotMenuStatus(false)
        setVisibleLookIndex(0)
        let path = window.location.pathname
        let displayId = path.split('/')[1]
        setHideLeftArrow(false)
        setHideRightArrow(false)

        window.history.replaceState({ page: "display_page" }, "display_page", '/' + window.location.search);
        window.history.replaceState({ page: "display_page" }, "display_page", (displayId ? displayId : displays[0].id) + window.location.search);
    }

    //post message add to cart
    const addProductToCart = (p) => {
        setRefreshHeart(true)
        sendMessage('addArticleToCart', { articleId: p.smc })
        let tmp = cartSmc
        tmp.push(p.smc)
        setCartSmc(tmp)
        setTimeout(() => {
            setRefreshHeart(false)
        }, 10);
    }

    //post message remove to cart
    const removeProductFromCart = (p) => {
        sendMessage('removeArticleFromCart', { articleId: p.smc })
        setRefreshHeart(true)
        let tmp = cartSmc
        tmp.map((el, index) => {
            if (el === p.smc) {
                tmp.splice(index, 1)
            }
        })
        setCartSmc(tmp)
        setTimeout(() => {
            setRefreshHeart(false)

        }, 10);

    }

    //reset cache button
    const clearCache = () => {
        eventService.resetCache()
        window.location.href = window.location.origin + '/' + window.location.search
    }


    //change visible look on points click
    const setVisibleHotspotDisplay = (index, bool) => {
        setVisibleLookIndex(index);
        if (!bool) {
            no_look_products[index].products.map((p, ind) => {
                eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
            })
            window.history.pushState({ page: "product_page" }, "product_page", 'products/' + index + window.location.search);
        } else {
            let path = window.location.pathname
            let displayId = path.split('/')[1]
            let hotspotId = path.split('/')[2]
            eventService.setImage('product', look_products[index].lookImageLink, look_products[index].id, cacheSuppurted)
            look_products[index].products.map((p, ind) => {
                eventService.setImage('product', p.imageLink, p.smc, cacheSuppurted)
            })
            window.history.replaceState({ page: "display_page" }, "display_page", '/' + window.location.search);
            window.history.pushState({ page: "display_page" }, "display_page", displayId + '/' + hotspotId + '/' + window.location.search);
            window.history.replaceState({ page: "look_page" }, "look_page", index + window.location.search);
        }
        setShowLook(bool)
    }

    let hotspot_points = ''
    if (points && points.length > 0)
        hotspot_points = points.map((p, index) => <React.Fragment key={p.x + p.y + index}>
            <div className={ 'hotspot_point _' + tertiary_color.substring(1) } style={{ position: 'absolute', top: (window.innerHeight - p.y < (showCarousel ? 150 : 40) ? window.innerHeight - (showCarousel ? 150 : 40) : p.y < 0 ? 0 : p.y) + 'px', left: (window.innerWidth - p.x < 40 ? window.innerWidth - 40 : p.x < 0 ? 0 : p.x) + 'px', color: tertiary_color, backgroundColor: '#ffffff' }} onClick={() => openHotspotMenu(index)}>
                <div className='hotspot_point_border' style={{ position: 'absolute', color: tertiary_color }}>
                </div>

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor" color={{ tertiary_color }} className="bi bi-plus hotspot_content_point" viewBox="0 0 16 16">
                    <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                </svg>
            </div >
        </React.Fragment >)

    let areasOptions = areas.map((area) => <option key={area.id} value={area.id}>{area.name}</option>)

    let filterMenu = ''
    filterMenu = <div id='filter_menu' style={{ backgroundColor: default_bg_color, borderColor: default_color, color: default_color }}>
        <select id='select_area' onChange={(e) => handleChangeArea(e)} value={selectedArea} style={{ backgroundColor: default_color, borderColor: default_bg_color, color: default_bg_color }}>
            <option value='0'>Select Area...</option>
            {areasOptions}
        </select>
        <button id='apply_button' style={{ backgroundColor: default_color, borderColor: default_bg_color, color: default_bg_color }} onClick={() => filterByArea()}>APPLY</button>
        <button id='reset_button' style={{ backgroundColor: default_color, borderColor: default_bg_color, color: default_bg_color }} onClick={reset}>RESET</button>
    </div >


    let looks = ''
    if (look_products.length > visibleLookIndex)
        looks = look_products[visibleLookIndex].products.map((p, index) =>
            <div key={p.smc} className={!articlesSmc.includes(p.smc) ? 'empty product_card' : 'product_card full'} id={'card_' + p.smc} >
                {!cartSmc.includes(p.smc) ?
                    <img className='heart' src={require('../resources/img/' + tertiary_color.substring(1) + '/heart.png')} width={28} height={28} onClick={() => addProductToCart(p)} /> :
                    <img className='heart' src={require('../resources/img/' + tertiary_color.substring(1) + '/heart_fill.png')} width={28} height={28} onClick={() => removeProductFromCart(p)} />
                }
                <img className="product_img" id={p.smc} alt='loading' src={require('../resources/img/loader.gif')} />
                <div>  <div className='product_name'>{articlesMap.get(p.smc) ? articlesMap.get(p.smc) : 'No name'}</div></div>
                <div className='product_smc'>{p.smc}</div>
                <button className='see_details_button' disabled={!articlesSmc.includes(p.smc) ? true : false} style={{ backgroundColor: tertiary_color, color: '#ffffff', border: '1px solid ' + default_bg_color, opacity: articlesSmc.includes(p.smc) ? 1 : 0.6 }} onClick={() => openDetailView(p)}>SEE DETAILS</button>
            </div>)


    let no_looks = ''
    if (no_look_products.length > visibleLookIndex && no_look_products[visibleLookIndex] && no_look_products[visibleLookIndex].products && showlook == false)
        no_looks = no_look_products[visibleLookIndex].products.map((p, index) =>
            <div key={p.smc} className={!articlesSmc.includes(p.smc) ? 'empty product_card' : 'product_card full'} id={'card_' + p.smc} >
                {!cartSmc.includes(p.smc) ?
                    <img className='heart' src={require('../resources/img/' + tertiary_color.substring(1) + '/heart.png')} width={28} height={28} onClick={() => addProductToCart(p)} /> :
                    <img className='heart' src={require('../resources/img/' + tertiary_color.substring(1) + '/heart_fill.png')} width={28} height={28} onClick={() => removeProductFromCart(p)} />
                }
                <img className="product_img" id={p.smc} src={require('../resources/img/loader.gif')} />
                < div > <div className='product_name'>{articlesMap.get(p.smc) ? articlesMap.get(p.smc) : 'No name'}</div></div>
                < div className='product_smc'> {p.smc}</div >
                <button className='see_details_button' disabled={!articlesSmc.includes(p.smc) ? true : false} style={{ backgroundColor: tertiary_color, color: '#ffffff', border: '1px solid ' + default_bg_color, opacity: articlesSmc.includes(p.smc) ? 1 : 0.6 }} onClick={() => openDetailView(p)}>SEE DETAILS</button>
            </div >)

    let lookImage = ''
    if (look_products.length > 0 && showlook)
        lookImage = <div key={look_products[visibleLookIndex].id} className='look_card'>

            <img className="look_img" id={look_products[visibleLookIndex].id} alt='loading'
                src={require('../resources/img/loader.gif')} />
            <div className='look_name'>{look_products[visibleLookIndex].lookName}</div>
        </div>

    let showLook = <div className='flex'>
        {displays.length > 0 && look_products.length > 0 && look_products[visibleLookIndex].products && look_products[visibleLookIndex].products.length > 0 && showlook ? looks :
            (displays.length > 0 && no_look_products.length > 0 && no_look_products[visibleLookIndex].products && no_look_products[visibleLookIndex].products.length > 0 && showlook == false ? no_looks : '')}
    </div>

    let displays_pointers_look = ''
    displays_pointers_look = look_products.map((l, index) => <div key={index + l.id + 'look'} className={visibleLookIndex == index && showlook ? 'selected_display_point' : 'display_point'} style={{ background: visibleLookIndex == index && showlook ? default_color : default_bg_color + '99' }} onClick={() => setVisibleHotspotDisplay(index, true)}></div>)

    let displays_pointers_no_look = ''
    displays_pointers_no_look = no_look_products.map((l, index) => <div key={index + l.id + 'no_look'} className={visibleLookIndex == index && !showlook ? 'selected_display_point' : 'display_point'} style={{ background: visibleLookIndex == index && !showlook ? default_color : default_bg_color + '99' }} onClick={() => setVisibleHotspotDisplay(index, false)}></div>)

    let hotspotMenu = ''
    hotspotMenu = <div id='hotspot_menu' style={{ backgroundColor: default_bg_color_op }}>
        <div className='exit_hotspot_menu_button' onClick={() => exitHotspot()} /*style={{ border: '1px solid ' + default_color, background: default_bg_color }}*/>
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" fill={'#ffffff'} className="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
        </div>

        {displays.length > 0 && look_products.length > 0 && look_products[visibleLookIndex].products.length > 0 && showlook ? lookImage : ''}

        <ScrollContainer onScroll={() => checkArrow(look_products, no_look_products)} className={displays.length > 0 && look_products.length > 0 && look_products[visibleLookIndex].products.length > 0 && showlook ? 'products_container' : 'products_container look_products_container'}>
            {showLook}
        </ScrollContainer>

        <div className='display_points_cont'>
            {look_products.length + no_look_products.length > 1 ? displays_pointers_look : ''}
            {look_products.length + no_look_products.length > 1 ? displays_pointers_no_look : ''}
        </div>

        {hideLeftArrow ? '' : <div className='left_arrow' onClick={scrollLeft} /*style={{ backgroundColor: default_bg_color, border: '1px solid ' + secondary_color }}*/>

            <img src={
                disabledLeftArrow ? require('../resources/img/disabled-back-arrow.png') :
                    require('../resources/img/back-arrow.png')} width={40} height={40} className='previous_arrow'></img>
        </div>}

        {hideRightArrow ? '' : <div className='right_arrow' onClick={scrollRight} /* style={{ backgroundColor: default_bg_color, border: '1px solid ' + secondary_color }}*/>
            <img src={
                disabledRightArrow ? require('../resources/img/disabled-next-arrow.png') :
                    require('../resources/img/next-arrow.png')} width={40} height={40} className='next_arrow'></img>
        </div>}
    </div>

    let map_style = {
        width: 'auto',
        'max-width': '135px',
        'max-height': '86px'
    }
    let map_style2 = {
        'max-width': '600px',
        'max-height': '378px'
    }

    let thumbnails = ''
    if (thumbImages && thumbImages.length > 0)
        thumbnails = thumbImages.map((i, index) =>
            <button key={i.id + '_thumb'} type="button" tabIndex="0" aria-pressed="false" aria-label="Go to Slide 3" className="image-gallery-thumbnail" onClick={(e) => viewPoint(displays, e, index, false, false)}  >
                <span className="image-gallery-thumbnail-inner">
                    <img id={'thumb-' + i.id} key={i.id}
                        className={selectedImageId == index ? "image-gallery-thumbnail-image selected-image" : "image-gallery-thumbnail-image"}
                        src={i.thumbnail} alt='loading'
                        style={{ borderColor: selectedImageId == index ? '#ffffff' : '' }} />
                    <div className="image-gallery-thumbnail-label"><div>
                        <div className="gucci_text thumbnail_hover " />  </div>
                        <div className="gucci_text thumbnail_hover"></div>
                    </div></span></button >)


    if (redirect) {
        if (redirect == 'notauth') {
            return <NotAuth />
        } else if (redirect == 'cookie') {
            return <CookieDisabled />
        } {
            return <GenericError />
        }
    }
    else if (!onLoading && displays) {
        return (<div style={{ maxHeight: '100vh', overflow: 'hidden' }}>

            {selectedImageId <= 0 || (hotspotMenuStatus && hideLeftArrow) ? '' :
                <div className={disabledLeftArrow ? 'disabled_left_arrow left_arrow' : 'left_arrow'} onClick={goPrevious} /*style={{ backgroundColor: disabledLeftArrow ? default_bg_color_disabled : default_bg_color, border: '1px solid ' + secondary_color }}*/>
                    <img src={
                        disabledLeftArrow ? require('../resources/img/disabled-back-arrow.png') :
                            require('../resources/img/back-arrow.png')} width={40} height={40}></img>
                </div>}

            {selectedImageId >= displays.length - 1 || (hotspotMenuStatus && hideRightArrow) ? '' :
                <div className={disabledRightArrow ? 'disabled_right_arrow right_arrow' : 'right_arrow'} onClick={goNext}
                    /*style={{ backgroundColor: disabledRightArrow ? default_bg_color_disabled : default_bg_color, border: '1px solid ' + secondary_color }}*/>
                    <img src={
                        disabledRightArrow ? require('../resources/img/disabled-next-arrow.png') :
                            require('../resources/img/next-arrow.png')} width={40} height={40}></img>
                </div>}

            {displays && displays.length > 0 ? <div className='image' onClick={() => setOpenMapStatus(false)}>
                {selected_image}
            </div> : ''}

            {displays && displays.length > 0 ?
                <div className='index' style={{ bottom: showCarousel ? (openMapStatus ? '405px' : '130px') : '20px' }}>
                    {selectedImageId + 1}/{displays.length}
                </div> : ''}

            {displays && displays.length > 0 ?
                <div className='scroll_cont'> <ScrollContainer /* onScroll={() => setOpenMapStatus(false)}*/ style={{ display: showCarousel ? 'block' : 'none' }} className={mapLink ? (openMapStatus ? " thumbnails_container_open scroll_thumb thumbnails_container" : (!disabledCarousel ? "scroll_thumb thumbnails_container" : 'scroll_thumb thumbnails_container op-7')) : 'thumbnails_container_no_map'}>
                    <div>{thumbnails}</div>
                </ScrollContainer></div>
                : <div className='loader_cont' ><div className='loader-line' style={{ backgroundColor: default_color + '30' }}></div>
                </div>}

            <div id='filter_button' style={{ backgroundColor: tertiary_color, /*border: '1px solid ' + default_bg_color,*/ display: areas.length < 2 ? 'none' : 'block' }} onClick={() => setFilterMenuStatus(!filterMenuStatus)}>
                <img src={require('../resources/img/filter' /* + colors.primary + */ + '-white' + '.png')} width={27} height={27}></img>
            </div>

            <div className='star_button' style={{ backgroundColor: default_bg_color, border: '1px solid ' + secondary_color }} >
                <svg xmlns="http://www.w3.org/2000/svg" width="22" height="25" fill={default_color} className="bi bi-trash3" viewBox="0 0 16 16" onClick={() => clearCache()}>
                    <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5ZM11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H2.506a.58.58 0 0 0-.01 0H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1h-.995a.59.59 0 0 0-.01 0H11Zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5h9.916Zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47ZM8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5Z" />
                </svg>
            </div>
            {/* {mapLink ?? <TransformWrapper>
                {({ zoomIn, zoomOut, ...rest }) => (

                    // <div className={openMapStatus ? 'map_thumb_open' : 'map_thumb'} style={{ backgroundImage: 'url("https://showroom.globalbit.gucci/NOTDeletethisFolder/showroom_maps/cosmogonie_mapV3.png")', backgroundSize: 'contain' }}>
                    <div className={openMapStatus ? 'map_thumb_open' : 'map_thumb'} onClick={!openMapStatus ? openMap : ''}>
                        <TransformComponent wrapperStyle={openMapStatus ? map_style2 : map_style}>
                            <img src={mapLink} style={openMapStatus ? map_style2 : map_style} />

                            <div id="displays_content"> </div>
                        </TransformComponent>


                        <img onClick={openMap}
                            className='open_map_button pointer'
                            src="https://d1icd6shlvmxi6.cloudfront.net/gsc/LA2YE3/43/d6/b7/43d6b73f2bc64bd6bae5f477f385685b/images/virtual_showroom/background_u303.svg?pageId=d256a5e9-38b8-4028-8ec5-70f0793f8716" tabindex="0" />


                        {!openMapStatus ? <img onClick={openMap}
                            className='open_map_icon pointer'
                            src="https://d1icd6shlvmxi6.cloudfront.net/gsc/LA2YE3/43/d6/b7/43d6b73f2bc64bd6bae5f477f385685b/images/virtual_showroom/icon_u304.svg?pageId=d256a5e9-38b8-4028-8ec5-70f0793f8716" />
                            :
                            <img onClick={openMap} src={require('.././resources/img/minus.png')} style={openMapStatus ? map_style2 : map_style} className='open_map_icon pointer' />
                        }  </div>

                )
                }
            </TransformWrapper > } */}
            {points && points.length > 0 ? hotspot_points : ''}
            {filterMenuStatus ? filterMenu : ''}
            {hotspotMenuStatus ? hotspotMenu : ''}
            <Toaster />
        </div >)
    }
    else {
        return (<div className='loader_cont' style={{ backgroundColor: default_bg_color }} >
            <div className='loading_text' style={{ color: default_color }}>LOADING</div>
            <div className={'loader-line _' + default_color.substring(1)} style={{ backgroundColor: default_color + '30' }} ></div>
        </div>)
    }

}